import WorkNomadsData from "../../data/projects/workNomads.json"
import Title from "../../components/ProjectPages/Title"
import Section from "../../components/ProjectPages/Section"
import SocialLinks from "../../components/ProjectPages/SocialLinks";
import VideoSection from "../../components/ProjectPages/VideoSection";
import ImageSection from "../../components/ProjectPages/ImagesSection";

/*--------------------
* Building LearnWorks
----------------------*/
export default function WorkNomads() {
    var data = JSON.parse(JSON.stringify(WorkNomadsData));
    data = data[0];
    return (
        <div className="bg-orange-50 h-full pt-[65px] pb-[69px] px-4 md:px-auto w-full">
            <div className="md:w-3/4 w-full mx-auto">
                <Title title="Marketing Expert at WorkNomads" />
                <Section title="Quick intro" content={data.intro}/>
                <div className="text-center my-6">
                    {
                        data.social_links.map((val, i)=>{
                            return <SocialLinks key={i} link={val.link} icon={val.icon}/>
                        })
                    }
                </div>
                <VideoSection title="TikTok"> 
                    {
                        data.tiktok.map((val, i)=>{
                            return <iframe src={val} key={i} className="overflow-hidden h-[750px] w-full rounded-lg" ></iframe>
                        })
                    }
                </VideoSection>
                <VideoSection title="Instagram Reels"> 
                    {
                        data.instagram.map((val, i)=>{
                            return <iframe src={val} key={i} className="overflow-hidden h-[600px] w-full rounded-lg" ></iframe>
                        })
                    }
                </VideoSection>
                <Section title="Events" content={data.events_description}/>
                <VideoSection> 
                    {
                        data.events.map((val, i)=>{
                            return <iframe src={val} key={i} className="overflow-hidden h-[600px] w-full rounded-lg" ></iframe>
                        })
                    }
                </VideoSection>
                <Section title="Competitions" key={data.competitions_description} content={data.competitions_description}/>
                <div className="columns-1 sm:columns-2 md:columns-3 gap-4">
                    {
                        data.competition_images.map((val, i)=>{
                            return <>
                                <div className="break-inside-avoid mb-4">
                                    <img key={i} src={val} title="image" alt="image" className="w-full h-auto rounded transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"/>
                                </div>
                            </>
                        })
                    }
                </div>
            </div>
        </div>
    )
}
